// import {
//   scaffoldState,
//   scaffoldActions,
//   scaffoldMutations,
// } from "undo-redo-vuex";
import state from "./elements.state.js";
import getters from "./elements.getters.js";
import mutations from "./elements.mutations.js";
import actions from "./elements.actions.js";

export default {
  namespaced: true,
  getters,
  state: state,
  mutations: mutations,
  actions: actions,
};
