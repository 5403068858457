import { getBlankCanvasProps } from "@/utils";

const state = {
  canvasElements: [], // store all elements/charts here - primary warehouse
  //resetCanvasElements: [],
  stockImages: [],
  stockImagesPage: 1,
  canvas: getBlankCanvasProps(),
  isDirty: false,
  lastAddedChartId: null,
  activeElementId: null, // current selected element's ID
  activeElementEvent: null, // the event prop
  activeMoveable: null, // store the instance of moveable to access everywhere
  activeChartId: null, // activeChartId is different from activeElement-Id because it uses different moving plugin
};
export default state;
