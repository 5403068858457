import { generateID } from "@/utils";
import elementsService from "@/api/elements.service";

const getXY = (canvas, el) => {
  const x = canvas.width / 2 - el.width / 2;
  const y = canvas.height / 2 - el.height / 2;

  return {
    x,
    y,
  };
};

const setTransformAttrs = (context, node, id) => {
  const canvas = context.state.canvas;
  let elW = canvas.width / 4;
  let scale = [1, 1];
  let xy = {};

  if (node.getAttribute("viewBox")) {
    const viewBox = node.getAttribute("viewBox").split(" ");
    node.setAttribute("width", viewBox[2]);
    node.setAttribute("height", viewBox[3]);

    scale = [elW / viewBox[2], elW / viewBox[3]];
    xy = getXY(canvas, { width: viewBox[2], height: viewBox[3] });
  } else {
    const incomingW = node.getAttribute("width");
    const incomingH = node.getAttribute("height");

    scale = [elW / incomingW, elW / incomingH];
    xy = getXY(canvas, { width: incomingW, height: incomingH });
  }

  node.style.transform = `translate(${xy.x}px, ${xy.y}px) scale(${scale[0]}, ${scale[1]}) rotate(0deg)`;
  node.id = id;
};

const actions = {
  // IMPORTANT NOTE: the second argument of an action needs to be an object
  // Passing a DOM element or a string causes MAXIMUM CALL STACK error
  // The issue might be due to Vue DevTools as disabling the extension also resolves it
  updateElementById: (context, props) => {
    context.commit("updateElement", props);
    context.commit("makeDirty", true);
  },
  changeMoveable: (context, { activeMoveable }) => {
    context.commit("updateMoveable", activeMoveable);
  },

  getInitialElements: (context, initElements) => {
    context.commit("initElements", initElements);
  },
  resetEditor: (context) => {
    context.commit("resetEverything");
  },
  changeActiveElement: (context, obj) => {
    return new Promise((resolve, reject) => {
      context.commit("activeElementChanged", { id: obj.id, e: obj.e });
      resolve(obj);
    });
  },
  changeCanvasProperties: (context, props) => {
    context.commit("changeCanvasProps", props);
  },
  changeSaveStateToUnsaved: (context) => {
    context.commit("makeDirty", true);
  },
  changeActiveChart: (context, obj) => {
    context.commit("activeChartChanged", obj.id);
  },
  moveElementLayer: (context, { id, direction }) => {
    if (direction === "up") {
      context.commit("moveElementUp", id);
    } else {
      context.commit("moveElementDown", id);
    }
    context.commit("makeDirty", true);
  },
  deleteLayer: (context, obj) => {
    return new Promise((resolve) => {
      context.commit("removeElement", obj.id);
      context.commit("makeDirty", true);
      resolve(obj.id);
    });
  },
  addChartElement: (context, src) => {
    return new Promise((resolve) => {
      const canvas = context.state.canvas;

      const elW = parseInt(src.chart.width) || canvas.width / 1.25;
      const elH = parseInt(src.chart.height) || canvas.height / 2;

      const xy = getXY(canvas, { width: elW, height: elH });
      const id = generateID(5);
      const chartEl = {
        id,
        elType: "chart",
        position: {
          x: xy.x,
          y: xy.y,
        },
        src: {
          ...src,
          chart: {
            ...src.chart,
            id,
            type: src.chart.type,
            height: src.chart.height || elH,
            width: src.chart.width || elW,
          },
        },
      };

      context.commit("addChart", { chart: chartEl, id: id });
      context.commit("makeDirty", true);
      resolve(id);
    });
  },
  addTextElement: (context, { text, size }) => {
    return new Promise((resolve, reject) => {
      const id = generateID(5);
      const canvas = context.state.canvas;

      const elW = canvas.width / 2;
      const elH = size;

      const xy = getXY(canvas, { width: elW, height: elH });
      const textEl = {
        id,
        elType: "section",
        html: `<section id="${id}" data-font-url="" style="display: inline-block; width: ${elW}px; z-index: inherit; color: #222; font-size: ${size}px; font-weight: normal; font-family: Roboto; font-style: normal; text-decoration: none; text-align: center; transform: translate(${xy.x}px, ${xy.y}px) scale(1, 1) rotate(0deg)">${text}</section>`,
      };
      context.commit("addElement", textEl);
      context.commit("makeDirty", true);
      resolve(textEl);
    });
  },
  addHTMLElement: (context, attrs) => {
    return new Promise((resolve, reject) => {
      const id = generateID(5);
      const {
        elW,
        elH,
        borderColor,
        borderWidth,
        borderStyle,
        borderRadius,
        backgroundColor,
      } = attrs;

      const canvas = context.state.canvas;

      const xy = getXY(canvas, { width: parseInt(elW), height: parseInt(elH) });
      const htmlEl = {
        id,
        elType: "div",
        html: `<div id="${id}" style="display: inline-block; background-color: ${
          backgroundColor ? backgroundColor : ""
        }; border-color: ${borderColor}; border-width: ${borderWidth}; border-radius: ${borderRadius}; border-style: ${borderStyle}; height: ${elH}; width: ${elW}; z-index: inherit; transform: translate(${
          xy.x
        }px, ${xy.y}px) scale(1, 1) rotate(0deg)"></div>`,
        keepRatio: attrs.keepRatio,
      };
      context.commit("addElement", htmlEl);
      context.commit("makeDirty", true);
      resolve(htmlEl);
    });
  },
  addImgElement: (context, { imgEl }) => {
    return new Promise((resolve) => {
      const id = generateID(5);
      const node = imgEl.cloneNode(true);
      setTransformAttrs(context, node, id);

      const imgStr = new XMLSerializer()
        .serializeToString(node)
        .replace(/\bdata-v\S+/gi, "");

      const obj = {
        id,
        elType: "img",
        html: imgStr,
      };
      context.commit("addElement", obj);
      context.commit("makeDirty", true);
      resolve(node);
    });
  },
  addSVGElement: (context, { svgEl }) => {
    return new Promise((resolve) => {
      const id = generateID(5);
      const node = svgEl.cloneNode(true);
      setTransformAttrs(context, node, id);

      const paths = node.querySelectorAll("path, polygon, rect, circle");
      paths.forEach((p) => {
        p.setAttribute("fill", "#343e59");
      });

      const svgStr = new XMLSerializer()
        .serializeToString(node)
        .replace(/\bdata-v\S+/gi, "");

      const obj = {
        id,
        elType: "svg",
        html: svgStr,
      };
      context.commit("addElement", obj);
      context.commit("makeDirty", true);
      resolve(node);
    });
  },
  copyChart: (context, options) => {
    return new Promise((resolve) => {
      const id = generateID(5);
      const chartEl = {
        id,
        elType: "chart",
        position: options.position,
        src: {
          ...options.src,
          chart: {
            ...options.src.chart,
            id,
          },
        },
      };

      context.commit("addChart", { chart: chartEl, id: id });
      context.commit("makeDirty", true);
      resolve(id);
    });
  },
  copyElement: (context, options) => {
    return new Promise((resolve, reject) => {
      const clonedEl = {
        id: options.id,
        elType: options.elType,
        html: options.html,
      };
      context.commit("addElement", clonedEl);
      context.commit("makeDirty", true);
      resolve(clonedEl);
    });
  },
  setChartOptions: (context, options) => {
    context.commit("setChartOptions", options);
    context.commit("makeDirty", true);
  },
  setChartPosition: (context, options) => {
    context.commit("setChartPosition", options);
    context.commit("makeDirty", true);
  },
  getStockImages: (context, payload) => {
    return new Promise((resolve, reject) => {
      elementsService
        .getStockImages(payload)
        .then((response) => {
          const images = response.results;
          context.commit("setStockImages", images);
          context.commit("setStockImagesPage", payload.pageNumber);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default actions;
