import state from './auth.state.js'
import getters from './auth.getters.js'
import mutations from './auth.mutations.js'
import actions from './auth.actions.js'
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
