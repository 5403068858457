import brandService from "@/api/brand.service";
import { deleteS3Obj } from "@/libs/aws-manager";

const actions = {
  getUserUploadedFonts: (context) => {
    return new Promise((resolve, reject) => {
      brandService
        .getUserUploadedFonts()
        .then((response) => {
          const sheet = window.document.styleSheets[0];

          response.forEach((f) => {
            sheet.insertRule(
              `@font-face {font-family: ${f.font_name};font-style: normal;font-weight: normal;src:url(${f.font_url});}`,
              sheet.cssRules.length
            );
          });
          context.commit("setUserUploadedFonts", response);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getUserUploadedImages: (context) => {
    return new Promise((resolve, reject) => {
      brandService
        .getUserUploadedImages()
        .then((response) => {
          context.commit("setUserUploadedImages", response);

          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  uploadImage: (context, payload) => {
    return new Promise((resolve, reject) => {
      brandService
        .uploadImage(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  uploadFont: (context, payload) => {
    return new Promise((resolve, reject) => {
      brandService
        .uploadFont(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteImage: (context, payload) => {
    return new Promise((resolve, reject) => {
      brandService
        .deleteImage(payload.id)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteImages: (context, images) => {
    return new Promise((resolve, reject) => {
      brandService
        .deleteImages(images)
        .then((responses) => {
          if (responses && responses.length) {
            Promise.all(
              images.map((img) => {
                return deleteS3Obj(img.key);
              })
            )
              .then(() => {
                resolve(responses);
              })
              .catch((e) => {
                reject(e);
              });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteFont: (context, payload) => {
    return new Promise((resolve, reject) => {
      brandService
        .deleteFont(payload.id)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateColorPalette: function(context, payload) {
    return new Promise((resolve, reject) => {
      brandService
        .updateColorPalette(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
};

export default actions;
