import axios from "axios";
import config from "@/config";

const brandService = {
  getUserUploadedFonts() {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/fonts/`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  getUserUploadedImages() {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/images/`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  uploadImage(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${config.BASE_API_URL}/images/`,
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  uploadFont(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${config.BASE_API_URL}/fonts/`,
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  deleteImage(id) {
    return new Promise((resolve, reject) => {
      axios({
        method: "DELETE",
        url: `${config.BASE_API_URL}/images/${id}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  deleteImages(images) {
    return new Promise((resolve, reject) => {
      const reqs = images.map((img) => {
        return axios.delete(`${config.BASE_API_URL}/images/${img._id}`);
      });
      axios
        .all(reqs)
        .then(
          axios.spread((...responses) => {
            return resolve(responses);
          })
        )
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  deleteFont(id) {
    return new Promise((resolve, reject) => {
      axios({
        method: "DELETE",
        url: `${config.BASE_API_URL}/fonts/${id}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  updateColorPalette(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${config.BASE_API_URL}/users/${payload.userId}`,
        data: {
          color_palette: payload.palette,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
};

export default brandService;
