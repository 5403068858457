import axios from "axios";
import config from "@/config";

const loginService = {
  authenticate(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${config.BASE_API_URL}/authenticate`,
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  getUserInfo(id) {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/users/${id}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: config.BASE_API_URL + "/logout",
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  registerWP(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: "https://apexcharts.com/wp-json/wp/v2/users/register",
        data: payload,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  loginWP(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: "https://apexcharts.com/wp-json/authwp/login",
        data: {
          username: payload.email,
          password: payload.password,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  updatePassword(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: "https://apexcharts.com/wp-json/bdpwr/v1/update-password",
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  sendMailForgotPassword(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: "https://apexcharts.com/wp-json/bdpwr/v1/forgot-password-mail",
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  setPassword(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: "https://apexcharts.com/wp-json/bdpwr/v1/set-password",
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  updateMyAccount(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${config.BASE_API_URL}/users/${payload.id}`,
        data: payload.data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  deleteMyAccount(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "DELETE",
        url: `${config.BASE_API_URL}/users/${payload.id}`,
        data: {
          email: payload.email,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
};
export default loginService;
