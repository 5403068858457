import ObjectId from "@/libs/object-id";
import config from "../config";

const bucketName = config.AWS_BUCKET_NAME;
const bucketRegion = config.AWS_BUCKET_REGION;
const IdentityPoolId = config.AWS_IDENTITY_POOL_ID;

export const initS3 = () => {
  window.AWS.config.update({
    region: bucketRegion,
    credentials: new window.AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId,
    }),
  });
};

const getS3Obj = () => {
  return new window.AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: bucketName },
  });
};

export const s3upload = (file, userId, dir, changeFileName) => {
  const s3 = getS3Obj();

  return new Promise(function(resolve, reject) {
    const dirKey = `${userId}/${dir}/`;
    let fileName = file.name;

    if (changeFileName) {
      const ext = file.name.substr(file.name.lastIndexOf(".") + 1);

      fileName = ObjectId() + "." + ext;
    }

    const fileKey = dirKey + fileName;

    s3.upload(
      {
        Key: fileKey,
        Body: file,
        ACL: "public-read",
        ContentDisposition: "attachment",
        ContentType: file.type,
      },
      (err, data) => {
        if (err) {
          reject(err); // failure
        } else {
          resolve(data); // success
        }
      }
    );
  });
};

export const deleteS3Obj = (key) => {
  return new Promise((resolve, reject) => {
    const s3 = getS3Obj();

    const params = { Key: key, Bucket: bucketName };
    s3.deleteObject(params, (err, data) => {
      if (err) {
        return reject("There was an error deleting your file: ", err.message);
      } else {
        resolve(data);
      }
    });
  });
};

export const deleteS3Folder = (folderName) => {
  return new Promise((resolve, reject) => {
    const albumKey = folderName + folderName.endsWith("/") ? "" : "/";

    const s3 = getS3Obj();

    const params = { Bucket: bucketName, Prefix: albumKey };
    s3.listObjects(params, (err, data) => {
      if (data) {
        const objects = data.Contents.map((object) => {
          return { Key: object.Key };
        });
        if (objects.length === 0) return resolve();
        const params = {
          Bucket: bucketName,
          Delete: { Objects: objects },
        };
        s3.deleteObjects(params, (errDelete, dataDelete) => {
          if (errDelete) {
            return reject({
              message: `There was an error deleting item: ${errDelete.message}`,
            });
          } else {
            return resolve(dataDelete);
          }
        });
      } else {
        return resolve();
      }
    });
  });
};

export const loadAWSsdk = () => {
  return new Promise((resolve, reject) => {
    const scriptName = "aws-jssdk";
    var js,
      fjs = document.getElementsByTagName("script")[0];
    if (document.getElementById(scriptName)) {
      initS3();
      return resolve();
    } else {
      js = document.createElement("script");
      js.scriptName = scriptName;
      js.src = "//sdk.amazonaws.com/js/aws-sdk-2.45.0.js";
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = function() {
        initS3();
        resolve();
      };
    }
  });
};
