<template>
  <div id="app">
    <div class="container-fluid page-content">
      <component :is="this.$route.meta.layout || 'div'">
        <router-view />
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  watch: {
    $route() {
      this.routeChanged();
    },
  },

  mounted: function() {
    this.checkUserAuth();
  },
  methods: {
    async checkUserAuth() {
      try {
        let token = window.localStorage.getItem("_verificationToken");

        if (token) {
          await this.$store.dispatch("authModule/authenticateFromToken", token);
          let userId = window.localStorage.getItem("userId");

          const res = await this.$store.dispatch(
            "authModule/getUserInfo",
            userId
          );
          if (res.status === 401) {
            this.$router.push("/login");
            return;
          }

          // after getting the user info, also set the color palette here
          Vue.component("extended-el-color-picker", {
            extends: window.ELEMENT.ColorPicker,
            props: {
              predefine: {
                default: function() {
                  return res.data.color_palette;
                },
              },
            },
          });

          this.$store.dispatch("brandModule/getUserUploadedFonts");
        }
      } catch (e) {
        return e;
      }
    },
    routeChanged() {
      const routeName = this.$route.name;

      document.body.classList.add(routeName);
    },
  },
};
</script>
