const getters = {
  currentProject: (state) => {
    return state.currentProject;
  },
  templates: (state) => {
    return state.templates;
  },
  projects: (state) => {
    return state.projects;
  },
  checkedProjects: (state) => {
    return state.checkedProjects;
  },
  folders: (state) => {
    return state.folders;
  },
};

export default getters;
