import VueRouter from "vue-router";
import VueWait from "vue-wait";
import VueMeta from "vue-meta";
import FontPicker from "font-picker-vue";
import VuePapaParse from "vue-papa-parse";
import VueDragResize from "vue-drag-resize";
import Fragment from "vue-fragment";
import VueMasonry from "vue-masonry-css";
import Loading from "vue-loading-overlay";
import VueClipboard from "vue-clipboard2";

// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import { setAxiosDefaultHeaders } from "@/utils";

setAxiosDefaultHeaders();
VueClipboard.config.autoSetContainer = true;

export default (Vue, ...params) => {
  params.filter((el) => typeof el === "object").map((le) => Vue.use(le));
  Vue.use(window.ELEMENT, { locale: window.ELEMENT.lang.en });

  Vue.component("extended-el-color-picker", {
    extends: window.ELEMENT.ColorPicker,
  });

  Vue.use(VueRouter);
  Vue.use(Fragment.Plugin);
  Vue.use(VueWait);
  Vue.use(VueMeta);
  Vue.use(VuePapaParse);
  Vue.component("vue-drag-resize", VueDragResize);
  Vue.component("font-picker", FontPicker);
  Vue.component("apexchart", VueApexCharts);
  Vue.use(vueCheetahGrid);
  Vue.use(VueMasonry);
  Vue.use(VueClipboard);
  Vue.component("loading", Loading);
};
