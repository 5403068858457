const getters = {
  userUploadedFonts: (state) => {
    return state.userUploadedFonts;
  },
  userUploadedImages: (state) => {
    return state.userUploadedImages;
  },
};

export default getters;
