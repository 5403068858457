const mutations = {
  setUserUploadedFonts: (state, value) => {
    state.userUploadedFonts = value;
  },
  setUserUploadedImages: (state, value) => {
    state.userUploadedImages = value;
  },
};

export default mutations;
