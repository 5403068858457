import App from "@/App.vue";

import router from "@/router";
import store from "@/store";

import VueWait from "vue-wait";
import plugins from "@/plugins";
import { mapGetters, mapActions } from "vuex";
import { getGoogleFontList } from "@/utils";

import "@/scss/styles.scss";
import "./polyfills";

Vue.config.productionTip = false;

plugins(Vue);

// The following code adds support for dynamic styling in psuedo elements (:before,:after)
// Such an example can be found when user wants to add overlay on the image background
// editor.component.vue file has such example
Vue.component("v-style", {
  render: function(createElement) {
    return createElement("style", this.$slots.default);
  },
});

Vue.mixin({
  data: function() {
    return {
      GOOGLE_FONTS_API_KEY: "AIzaSyCdQpqS36doLpz9L_7BhwpsVxXPn2x3uXs",
    };
  },
  computed: {
    ...mapGetters("elementsModule", [
      "canvasElements",
      "activeChartId",
      "activeElementId",
    ]),
    ...mapActions("elementsModule", ["setChartOptions"]),
    publicPath: function() {
      return "/";
    },
    activeElement: function() {
      if (this.activeElementId) {
        return document.getElementById(this.activeElementId);
      }
      return null;
    },
    activeElementType: function() {
      if (this.activeElement) {
        return this.activeElement.nodeName.toLowerCase();
      }
      return null;
    },
    chartConfig: function() {
      if (this.activeChartId) {
        const chart = window.ApexCharts.getChartByID(this.activeChartId);
        return chart.w.config;
      }
      return null;
    },
    chartType: function() {
      if (this.activeChartId) {
        const chart = window.ApexCharts.getChartByID(this.activeChartId);
        return chart.w.config.chart.type;
      }
      return null;
    },
    isCartesianChart: function() {
      if (this.chartType) {
        const t = this.chartType;
        return (
          t !== "pie" && t !== "donut" && t !== "radialBar" && t !== "polarArea"
        );
      }
      return true;
    },
  },
  methods: {
    getChartConfig: function() {
      return window.ApexCharts.getChartByID(this.activeChartId);
    },
    loadProjectFonts: function(font) {
      if (typeof font.fontFamily !== "undefined") {
        var head = document.head;
        var link = document.createElement("link");

        const googleFontList = getGoogleFontList();
        if (googleFontList.indexOf(font.fontFamily) > -1) {
          link.rel = "stylesheet";
          link.setAttribute("crossorigin", "anonymous");
          // google fonts crossorigin attribute if not set, results into fonts not being loaded while saving as PNG

          link.href = `https://fonts.googleapis.com/css?family=${font.fontFamily}`;
          head.appendChild(link);
        } else {
          const sheet = window.document.styleSheets[0];

          // custom font - fontUrl must be present
          if (font.fontUrl) {
            sheet.insertRule(
              `@font-face {font-family: ${font.fontFamily};font-style: normal;font-weight: normal;src:url(${font.fontUrl});}`,
              sheet.cssRules.length
            );
          }
        }
      }
    },
    updateChartOptions(options) {
      return new Promise(async (resolve, reject) => {
        if (this.activeChartId) {
          //  window.ApexCharts.exec(this.activeChartId, "updateOptions", options);
          await this.$store.dispatch("elementsModule/setChartOptions", {
            id: this.activeChartId,
            options,
          });
          const chart = window.ApexCharts.getChartByID(this.activeChartId);
          await chart.updateOptions(options);
          this.loadProjectFonts({
            fontFamily: options.chart?.fontFamily,
            fontUrl: options.chart?.fontUrl,
          });
          resolve();
        }
      });
    },
    updateChartSeries(series) {
      return new Promise(async (resolve, reject) => {
        if (this.activeChartId) {
          await this.$store.dispatch("elementsModule/setChartOptions", {
            id: this.activeChartId,
            options: {
              series,
            },
          });
          const chart = window.ApexCharts.getChartByID(this.activeChartId);
          await chart.updateSeries(series);
          resolve();
        }
      });
    },
  },
});

const main = new Vue({
  store,
  router,
  wait: new VueWait(),
  ...App,
});

document.addEventListener("DOMContentLoaded", function() {
  main.$mount("#app");
});
