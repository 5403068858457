<template>
  <el-dropdown @command="openAccountMenu" trigger="click">
    <el-button class="btn-social-initial" type="primary">
      {{ initials && initials.substring(0, 1) }}
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="dashboard">
        <i class="el-icon-s-home"></i> My Workspace</el-dropdown-item
      >
      <el-dropdown-item command="newproject">
        <i class="el-icon-plus"></i> New Project</el-dropdown-item
      >
      <el-dropdown-item command="settings">
        <i class="el-icon-setting"></i> Settings</el-dropdown-item
      >
      <el-dropdown-item :divided="true" command="logout"
        >Logout</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getBlankCanvasProps } from "@/utils";

export default {
  data() {
    return {
      showLoginBox: false,
    };
  },

  computed: {
    ...mapGetters("authModule", ["isAuthenticated", "userInfo"]),
    ...mapGetters("elementsModule", ["isDirty"]),
    ...mapActions("authModule", ["logout"]),
    ...mapActions("elementsModule", [
      "changeCanvasProperties",
      "getInitialElements",
    ]),
    initials: function() {
      return this.userInfo?.display_name
        ? this.userInfo?.display_name
        : this.userInfo?.email;
    },
  },

  methods: {
    async openAccountMenu(command) {
      const me = this;
      switch (command) {
        case "dashboard":
          this.$router.push("/dashboard/saved/all");
          break;
        case "newproject":
          if (this.isDirty) {
            this.$confirm(
              "Changes you have made will not be saved",
              "Warning",
              {
                confirmButtonText: "Leave",
                cancelButtonText: "Stay on page",
                type: "warning",
              }
            )
              .then(async () => {
                try {
                  await this.$store.dispatch("elementsModule/resetEditor");

                  if (this.$route.fullPath !== "/c/new") {
                    this.$router.push("/c/new");
                  }
                } catch (e) {
                  return e;
                }
              })
              .catch((e) => {
                return e;
              });
          } else {
            await this.$store.dispatch("elementsModule/resetEditor");

            if (this.$route.fullPath !== "/c/new") {
              this.$router.push("/c/new");
            }
          }

          break;
        case "settings":
          this.$router.push("/dashboard/settings");
          break;
        case "logout":
          await this.$store.dispatch("authModule/logout");
          this.$router.push("/login");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "topbar.component.scss";
</style>
