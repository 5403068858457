import VueRouter from "vue-router";
import store from "../store/index";

import LayoutDashboard from "../layouts/dashboard.layout.vue";

const Editor = () => import("@/components/editor/editor.component");
const Login = () => import("@/components/login-page/login-page.component");
const Embed = () => import("@/components/embed/embed.component");

const Templates = () =>
  import("@/components/dashboard/templates/templates.component");
// const Folders = () =>
//   import("@/components/dashboard/folders/folders.component");
const Uploads = () =>
  import("@/components/dashboard/uploads/uploads.component");
const Settings = () =>
  import("@/components/dashboard/settings/settings.component");
const Saved = () => import("@/components/dashboard/saved/saved.component");
const Typography = () =>
  import("@/components/dashboard/brand-kit/typography/typography.component");
const ColorPalette = () =>
  import(
    "@/components/dashboard/brand-kit/color-palette/color-palette.component"
  );
const NotFound = () => import("@/components/static/not-found/not-found");

Vue.use(VueRouter);

const adminGuard = function(next) {
  if (store.state.authModule.isAdmin) {
    next();
  } else {
    next("/");
  }
};

const loginGuard = function(next) {
  if (localStorage.getItem("userId")) {
    next();
  } else {
    next("/login");
  }
};

const router = new VueRouter({
  base: "/",
  routes: [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/dashboard",
      redirect: "/dashboard/saved/all",
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound,
    },
    {
      path: "/admin",
      name: "admin",
      beforeEnter: (to, from, next) => {
        adminGuard(next);
      },
    },
    {
      path: "/c/:projectId",
      name: "editor",
      component: Editor,
    },
    {
      path: "/embed/:projectId",
      name: "embed",
      component: Embed,
    },
    {
      path: "/dashboard/templates",
      name: "templates",
      component: Templates,
      beforeEnter: (to, from, next) => {
        loginGuard(next);
      },
      meta: { layout: LayoutDashboard },
    },
    {
      path: "/dashboard/saved/:folderId",
      name: "saved",
      component: Saved,
      beforeEnter: (to, from, next) => {
        loginGuard(next);
      },
      meta: { layout: LayoutDashboard },
    },
    // the below route is deprecated as folders are moved to "saved"
    // {
    //   path: "/dashboard/folders/:folderId",
    //   name: "folders",
    //   component: Folders,
    //   beforeEnter: (to, from, next) => {
    //     loginGuard(next);
    //   },
    //   meta: { layout: LayoutDashboard },
    // },
    {
      path: "/dashboard/uploads",
      name: "uploads",
      component: Uploads,
      beforeEnter: (to, from, next) => {
        loginGuard(next);
      },
      meta: { layout: LayoutDashboard },
    },
    {
      path: "/dashboard/settings",
      name: "settings",
      component: Settings,
      beforeEnter: (to, from, next) => {
        loginGuard(next);
      },
      meta: { layout: LayoutDashboard },
    },
    {
      path: "/dashboard/typography",
      name: "typography",
      component: Typography,
      beforeEnter: (to, from, next) => {
        loginGuard(next);
      },
      meta: { layout: LayoutDashboard },
    },
    {
      path: "/dashboard/color-palette",
      name: "color-palette",
      component: ColorPalette,
      beforeEnter: (to, from, next) => {
        loginGuard(next);
      },
      meta: { layout: LayoutDashboard },
    },
  ],
  mode: "history",
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
