const getters = {
  canvasElements: (state) => {
    return state.canvasElements;
  },
  canvas: (state) => {
    return state.canvas;
  },
  activeElementId: (state) => {
    return state.activeElementId;
  },
  activeElementEvent: (state) => {
    return state.activeElementEvent;
  },
  activeMoveable: (state) => {
    return state.activeMoveable;
  },
  activeChartId: (state) => {
    return state.activeChartId;
  },
  lastAddedChartId: (state) => {
    return state.lastAddedChartId;
  },
  isDirty: (state) => {
    return state.isDirty;
  },

  stockImages: (state) => {
    return state.stockImages;
  },
  stockImagesPage: (state) => {
    return state.stockImagesPage;
  },

  // canUndo: (state) => {
  //   return state.canUndo;
  // },
  // canRedo: (state) => {
  //   return state.canRedo;
  // },
};

export default getters;
