import { extend, moveArray, getBlankCanvasProps } from "@/utils";

const mutations = {
  // emptyState: (state) => {
  //   state.canvasElements = [...(state.canvasElements || [])];
  // },
  initElements: (state, value) => {
    state.canvasElements = value;
  },
  addElement: (state, value) => {
    state.canvasElements.push(value);
  },
  addChart: (state, value) => {
    state.canvasElements.push(value.chart);
    state.lastAddedChartId = value.id;
  },
  resetEverything: (state) => {
    state.canvasElements = [];
    state.canvas = getBlankCanvasProps();
    state.isDirty = false;
    state.lastAddedChartId = null;
    state.activeElementId = null;
    state.activeElementEvent = null;
    state.activeMoveable = null;
    state.activeChartId = null;
  },
  updateElement: (state, value) => {
    const index = state.canvasElements.findIndex((el) => {
      return el.id === value.id;
    });

    if (index !== -1) {
      // standard approach
      // Vue.set(state.canvasElements, index, {
      //   ...state.canvasElements[index],
      //   html: value.html,
      // });
      // alternative approach #1 keeps reactivity
      // state.canvasElements[index].html = value.html;
      // ..
      // alternative approach #2 to avoid reactivity
      state.canvasElements[index] = {
        ...state.canvasElements[index],
        html: value.html,
      };
    }
  },
  makeDirty: (state, value) => {
    state.isDirty = value;
  },
  setChartOptions: (state, value) => {
    const index = state.canvasElements.findIndex((el) => {
      return el.id === value.id;
    });
    const chartOpts = extend(state.canvasElements[index].src, value.options);

    state.canvasElements[index] = {
      ...state.canvasElements[index],
      src: chartOpts,
    };
  },
  setChartPosition: (state, value) => {
    const index = state.canvasElements.findIndex((el) => {
      return el.id === value.id;
    });

    state.canvasElements[index] = {
      ...state.canvasElements[index],
      position: value.position,
    };
  },
  updateMoveable: (state, value) => {
    state.activeMoveable = value;
  },
  activeElementChanged: (state, value) => {
    state.activeElementId = value.id;
    state.activeElementEvent = value.e;
  },

  activeChartChanged: (state, value) => {
    state.activeChartId = value;
  },

  changeCanvasProps: (state, value) => {
    state.canvas = value;
  },
  moveElementUp: (state, id) => {
    const currIndex = state.canvasElements.findIndex((e) => {
      return e.id === id;
    });
    if (currIndex < state.canvasElements.length - 1) {
      moveArray(state.canvasElements, currIndex, currIndex + 1);
    }
  },
  moveElementDown: (state, id) => {
    const currIndex = state.canvasElements.findIndex((e) => {
      return e.id === id;
    });
    if (currIndex > 0) {
      moveArray(state.canvasElements, currIndex, currIndex - 1);
    }
  },
  removeElement: (state, id) => {
    const indexToDel = state.canvasElements.findIndex((e) => {
      return e.id === id;
    });

    if (indexToDel !== -1) {
      state.canvasElements.splice(indexToDel, 1);
    }
  },
  setStockImages: (state, value) => {
    state.stockImages = value;
  },
  setStockImagesPage: (state, value) => {
    state.stockImagesPage = value;
  },
};

export default mutations;
