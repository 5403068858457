const mutations = {
  authenticate: (state, payload) => {
    state.isAuthenticated = true;
  },
  authFromToken: (state) => {
    state.isAuthenticated = true;
  },
  unauth: (state) => {
    state.isAuthenticated = false;
    state.userInfo = null;
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
    if (payload) {
      state.isAdmin = payload.is_admin;
    }
  },
};

export default mutations;
