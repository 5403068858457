import axios from "axios";
import config from "@/config";

const projectsService = {
  getProjectById(payload) {
    return new Promise((resolve, reject) => {
      let url = `${config.BASE_API_URL}/projects/${payload.id}`;

      if (payload.is_template) {
        url = `${config.BASE_API_URL}/projects/template/${payload.id}`;
      }
      axios({
        method: "GET",
        url,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  getUserProjects(queryObj) {
    return new Promise((resolve, reject) => {
      let query = "";
      if (queryObj) {
        if (typeof queryObj.folderId !== "undefined") {
          query += `folder_id=${queryObj.folderId}`;
        }
      }

      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/projects/saved?${query}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  getTemplates() {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/projects/templates`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },

  getProjectsByFolderId(folderId) {
    //
  },
  saveProject(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${config.BASE_API_URL}/projects`,
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  updateProject(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${config.BASE_API_URL}/projects/${payload.id}`,
        data: payload.body,
      })
        .then((response) => {
          resolve(response.body);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  updateProjects(payloads) {
    return new Promise((resolve, reject) => {
      const reqs = payloads.map((payload) => {
        return axios.put(
          `${config.BASE_API_URL}/projects/${payload.id}`,
          payload.body
        );
      });
      axios
        .all(reqs)
        .then(
          axios.spread((...responses) => {
            return resolve(responses);
          })
        )
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  deleteProjects(projectIds) {
    return new Promise((resolve, reject) => {
      const reqs = projectIds.map((id) => {
        return axios.delete(`${config.BASE_API_URL}/projects/${id}`);
      });
      axios
        .all(reqs)
        .then(
          axios.spread((...responses) => {
            return resolve(responses);
          })
        )
        .catch((e) => {
          reject(e.response);
        });
    });
  },
};

export default projectsService;
