import Vuex from "vuex";
import auth from "./modules/auth";
import elements from "./modules/elements";
import brand from "./modules/brand";
import projects from "./modules/projects";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    authModule: auth,
    elementsModule: elements,
    brandModule: brand,
    projectsModule: projects,
  },
  strict: false,
});
export default store;
