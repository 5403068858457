"use strict";

const ISPROD = window.location.origin === "https://editor.apexcharts.com";

// const baseApiUrl = ISPROD
//   ? "https://api.apexcharts.com"
//   : "https://198.98.48.56";

const baseApiUrl = "https://api.apexcharts.com";

export default {
  ISPROD,
  BASE_API_URL: `${baseApiUrl}/api`,
  S3_THUMBNAIL_FOLDER: "thumbnails",
  S3_IMAGES_FOLDER: "images",
  S3_FONTS_FOLDER: "fonts",
  GOOGLE_CLIENT_ID:
    "592271743865-4ido148hpnir9khejh1081de111jf90f.apps.googleusercontent.com", // google_client_id is associated with juned.chhipa@gmail.com
  AWS_IDENTITY_POOL_ID: "us-east-1:95cfd7cd-2c06-4654-88d0-e20603f30ebc", // the amazon account associated with this is admin@apexcharts.com
  AWS_BUCKET_NAME: "apexcharts",
  AWS_BUCKET_REGION: "us-east-1",
  STRIPE_PRICEID_PLUS_LIVE: "price_1HtdDgHvKOQjMRPU5m5hbDvH",
  STRIPE_PRICEID_PLUS_TEST: "price_1HxsBhHvKOQjMRPUuUuRESOE",
  STRIPE_PUBLISHABLE_KEY_LIVE: "pk_live_M4kitLZEsdufMmpJe1ttpEtc",
  STRIPE_PUBLISHABLE_KEY_TEST: "pk_test_bHioBWWxJIElejBwklD4YcfZ",
};
