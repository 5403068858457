import state from "./projects.state.js";
import getters from "./projects.getters.js";
import mutations from "./projects.mutations.js";
import actions from "./projects.actions.js";

export default {
  namespaced: true,
  getters,
  state: state,
  mutations: mutations,
  actions: actions,
};
