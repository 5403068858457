import loginService from "@/api/auth.service";
import { setAxiosDefaultHeaders } from "@/utils";

const actions = {
  authenticate: (context, payload) => {
    return new Promise((resolve, reject) => {
      loginService
        .authenticate(payload)
        .then((response) => {
          if (response.data) {
            context.commit("authenticate", true);
          }
          if (response.token) {
            localStorage.setItem("_verificationToken", response.token);
            localStorage.setItem("userId", response.data._id);
          }

          setAxiosDefaultHeaders();
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  authenticateFromToken: (context) => {
    return new Promise((resolve, reject) => {
      context.commit("authFromToken");
      resolve();
    });
  },
  getUserInfo: async (context, id) => {
    try {
      const response = await loginService.getUserInfo(id);
      if (response.data) {
        context.commit("setUserInfo", response.data);
      }
      return response;
    } catch (e) {
      return e;
    }
  },
  registerWP: async (context, payload) => {
    try {
      return await loginService.registerWP(payload);
    } catch (e) {
      return e;
    }
  },
  loginWP: async (context, payload) => {
    try {
      return await loginService.loginWP(payload);
    } catch (e) {
      return e;
    }
  },
  deleteMyAccount(context, payload) {
    return new Promise((resolve, reject) => {
      loginService
        .deleteMyAccount(payload)
        .then((response) => {
          localStorage.clear();
          context.commit("unauth");

          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  updateMyAccount(context, payload) {
    return new Promise((resolve, reject) => {
      loginService
        .updateMyAccount(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  },
  updatePassword: (context, payload) => {
    return new Promise((resolve, reject) => {
      loginService
        .loginWP(payload)
        .then((validUser) => {
          loginService
            .updatePassword({
              email: payload.email,
              password: payload.newPassword,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  sendMailForgotPassword: (context, payload) => {
    return new Promise((resolve, reject) => {
      loginService
        .sendMailForgotPassword(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  setPassword: (context, payload) => {
    return new Promise((resolve, reject) => {
      loginService
        .setPassword(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updatePassword: (context, payload) => {
    return new Promise((resolve, reject) => {
      loginService
        .loginWP(payload)
        .then((validUser) => {
          loginService
            .updatePassword({
              email: payload.email,
              password: payload.newPassword,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  logout(context) {
    const promise = new Promise((resolve, reject) => {
      localStorage.clear();
      context.commit("unauth");
      resolve();
    });
    return promise;
  },
};
export default actions;
