import state from "./brand.state.js";
import getters from "./brand.getters.js";
import mutations from "./brand.mutations.js";
import actions from "./brand.actions.js";

export default {
  namespaced: true,
  getters,
  state: state,
  mutations: mutations,
  actions: actions,
};
