import axios from "axios";
import config from "@/config";

const elementsService = {
  getStockImages(payload) {
    return new Promise((resolve, reject) => {
      if (!payload.keyword) {
        payload.keyword = "chart";
      }
      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/stock-images/search?keyword=${payload.keyword}&page=${payload.pageNumber}&pageSize=${payload.pageSize}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
};

export default elementsService;
