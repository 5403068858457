import projectsService from "@/api/projects.service";
import foldersService from "@/api/folders.service";
import { deleteS3Obj } from "@/libs/aws-manager";
import config from "@/config";

const actions = {
  resetCurrentProject: (context) => {
    context.commit("setCurrentProject", {});
  },
  saveProject: (context, payload) => {
    return new Promise((resolve, reject) => {
      projectsService
        .saveProject(payload)
        .then((response) => {
          context.commit("elementsModule/makeDirty", false, { root: true });
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateProject: (context, payload) => {
    return new Promise((resolve, reject) => {
      projectsService
        .updateProject(payload)
        .then((response) => {
          context.commit("elementsModule/makeDirty", false, { root: true });
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  changeCheckedProjects: (context, checkedValues) => {
    context.commit("setCheckedProjects", checkedValues);
  },
  moveProjectsToFolder: (context, payloads) => {
    return new Promise((resolve, reject) => {
      projectsService
        .updateProjects(payloads)
        .then((responses) => {
          context.commit("setCheckedProjects", []);
          resolve(responses);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteProjects: (context, projectIds) => {
    return new Promise((resolve, reject) => {
      projectsService
        .deleteProjects(projectIds)
        .then((responses) => {
          const s3FileKeys = [];
          if (responses && responses.length) {
            responses.forEach((r) => {
              if (r.data) {
                const projectId = r.data.id;
                const userId = r.data.user_id;

                const s3Filekey = `${userId}/${config.S3_THUMBNAIL_FOLDER}/${projectId}.png`;
                s3FileKeys.push(s3Filekey);
              }
            });

            Promise.all(
              s3FileKeys.map((d) => {
                return deleteS3Obj(d);
              })
            )
              .then(() => {
                context.commit("setDeletedProjects", responses);
                resolve(responses);
              })
              .catch((e) => {
                reject(e);
              });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getProjectById: (context, payload) => {
    return new Promise((resolve, reject) => {
      projectsService
        .getProjectById(payload)
        .then((response) => {
          context.commit("elementsModule/initElements", response.elements, {
            root: true,
          });
          context.commit("setCurrentProject", response);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getUserProjects: (context, queryObj) => {
    return new Promise((resolve, reject) => {
      projectsService
        .getUserProjects(queryObj)
        .then((response) => {
          context.commit("setSavedProjects", response);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getTemplates: (context) => {
    return new Promise((resolve, reject) => {
      projectsService
        .getTemplates(context)
        .then((response) => {
          context.commit("setTemplates", response);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getFolders: (context) => {
    return new Promise((resolve, reject) => {
      foldersService
        .getFolders()
        .then((response) => {
          context.commit("setFolders", response);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  createFolder: (context, payload) => {
    return new Promise((resolve, reject) => {
      foldersService
        .createFolder(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateFolder: (context, payload) => {
    return new Promise((resolve, reject) => {
      foldersService
        .updateFolder(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  deleteFolder: (context, payload) => {
    return new Promise((resolve, reject) => {
      foldersService
        .deleteFolder(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getProjectsByFolderId: (folderId) => {
    return new Promise((resolve, reject) => {
      projectsService
        .getProjectsByFolderId(folderId)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default actions;
