const getters = {
  isAuthenticated: (state) => {
    return state.isAuthenticated;
  },
  userInfo: (state) => state.userInfo,
  isAdmin: (state) => {
    return state.userInfo && state.userInfo.is_admin;
  },
};

export default getters;
