<template>
  <div class="page-layout-dashboard">
    <dropdown-account class="dashboard-top-menu"></dropdown-account>

    <div class="d-flex d-flex-justify-between">
      <dashboard-sidebar></dashboard-sidebar>

      <div class="main-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/dashboard/sidebar/sidebar.component";
import dropdownAccount from "@/components/common/topbar/dropdown-account.component";

export default {
  name: "layout-dashboard",
  components: {
    "dashboard-sidebar": sidebar,
    "dropdown-account": dropdownAccount,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-top-menu {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 7px;
}
</style>
