const mutations = {
  setCurrentProject: (state, value) => {
    state.currentProject = value;
  },
  setTemplates: (state, value) => {
    state.templates = value;
  },
  setSavedProjects: (state, value) => {
    state.projects = value;
  },
  setCheckedProjects: (state, value) => {
    state.checkedProjects = value;
  },
  setFolders: (state, value) => {
    state.folders = value;
  },
  setDeletedProjects: (state, responses) => {
    responses.forEach((r) => {
      if (r.data) {
        const indexToDel = state.projects.findIndex((c) => {
          return c._id === r.data.id;
        });
        const indexToDelChecked = state.checkedProjects.findIndex((c) => {
          return c === r.data.id;
        });
        if (indexToDel !== -1) {
          state.projects.splice(indexToDel, 1);
        }
        if (indexToDelChecked !== -1) {
          state.checkedProjects.splice(indexToDelChecked, 1);
        }
      }
    });
  },
};

export default mutations;
