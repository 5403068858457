import axios from "axios";
import config from "@/config";

const foldersService = {
  getFolders() {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `${config.BASE_API_URL}/folders/`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  createFolder(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: `${config.BASE_API_URL}/folders/`,
        data: payload,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  updateFolder(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url: `${config.BASE_API_URL}/folders/${payload.id}`,
        data: payload.data,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
  deleteFolder(payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: "DELETE",
        url: `${config.BASE_API_URL}/folders/${payload.id}`,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response);
        });
    });
  },
};

export default foldersService;
