<template>
  <div class="sidebar sidebar-dashboard sidebar-fda2773a">
    <div class="sidebar-header mb4">
      <p class="logo">
        <img
          @click="gotoHome()"
          class="mt1 img-logo"
          :src="`${publicPath}assets/apexcharts-graphmaker-logo-white.svg`"
        />
      </p>
      <el-button
        class="btn-create-new d-block"
        type="success"
        icon="el-icon-plus"
        @click="openNewProject"
        >New Project</el-button
      >
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :router="true"
      @open="menuToggled"
      @close="menuToggled"
      :default-openeds="openMenus"
    >
      <el-menu-item
        :class="{ active: selectedMenu === 'templates' }"
        @click="changeMenu('templates')"
        ><i class="ico"> <IcoDashboard></IcoDashboard> </i>
        <span>Browse Templates</span></el-menu-item
      >
      <el-menu-item
        :class="{ active: selectedMenu === 'saved' }"
        @click="changeMenu('saved')"
        v-if="folders.length === 0"
        index="/dashboard/saved/all"
      >
        <i class="ico"> <IcoCharts></IcoCharts> </i>
        <span>My Saved Projects</span></el-menu-item
      >
      <el-submenu
        v-else
        :class="{ active: selectedMenu === 'saved' }"
        index="saved"
      >
        <template slot="title">
          <i class="ico"> <IcoCharts></IcoCharts> </i>
          <span>My Saved Projects</span>
        </template>
        <el-menu-item
          @click="redirectToFolder(folder._id)"
          v-for="folder in folders"
          :class="{ active: selectedMenu === folder._id }"
          :key="folder.id"
        >
          <i class="ico"> <IcoFolder></IcoFolder> </i>
          <span>{{ folder.name }}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        :class="{ active: selectedMenu === 'uploads' }"
        @click="changeMenu('uploads')"
      >
        <i class="ico"> <IcoUploads></IcoUploads> </i>
        <span>My Uploads</span></el-menu-item
      >

      <el-submenu
        :class="{ active: selectedMenu === 'brand-kit' }"
        @click="changeMenu('brand-kit')"
        :collapse="false"
        index="brand-kit"
      >
        <template slot="title">
          <i class="ico"> <IcoBranding></IcoBranding> </i>
          <span>Brand Kit</span>
        </template>
        <el-menu-item
          :class="{ active: selectedMenu === 'typography' }"
          @click="changeMenu('typography')"
        >
          <i class="ico"> <IcoFonts></IcoFonts> </i>
          <span>Typography</span>
        </el-menu-item>
        <el-menu-item
          :class="{ active: selectedMenu === 'color-palette' }"
          @click="changeMenu('color-palette')"
        >
          <i class="ico"> <IcoPalette></IcoPalette> </i>
          <span>Color Palette</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        :class="{ active: selectedMenu === 'settings' }"
        @click="changeMenu('settings')"
      >
        <i class="ico"> <IcoSettings></IcoSettings> </i>
        <span>Settings</span></el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
import IcoHome from "@/components/common/ico/home-white-18dp.svg";
import IcoDashboard from "@/components/common/ico/dashboard-white-18dp.svg";
import IcoCharts from "@/components/common/ico/insert_chart_outlined-24px.svg";
import IcoFolder from "@/components/common/ico/folder_open-white-18dp.svg";
import IcoUploads from "@/components/common/ico/cloud_upload-24px.svg";
import IcoBranding from "@/components/common/ico/business_center-white-18dp.svg";
import IcoFonts from "@/components/common/ico/title-24px.svg";
import IcoPalette from "@/components/common/ico/palette-24px.svg";
import IcoSettings from "@/components/common/ico/settings-white-18dp.svg";
import { loadAWSsdk } from "@/libs/aws-manager";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      openMenus: ["folders", "brand-kit"],
    };
  },
  components: {
    IcoHome,
    IcoDashboard,
    IcoCharts,
    IcoFolder,
    IcoUploads,
    IcoBranding,
    IcoSettings,
    IcoFonts,
    IcoPalette,
  },
  computed: {
    ...mapGetters("authModule", ["isAuthenticated"]),
    ...mapGetters("projectsModule", ["folders"]),
    ...mapActions("projectsModule", ["getFolders"]),
    selectedMenu: function() {
      if (
        this.$route.params &&
        this.$route.params.folderId &&
        this.$route.params.folderId !== "all"
      ) {
        return this.$route.params.folderId;
      }
      return this.$route.name;
    },
  },
  mounted: function() {
    loadAWSsdk();
    this.getAllFolders();
  },
  methods: {
    gotoHome() {
      if (this.isAuthenticated) {
        this.$router.push("/dashboard/saved/all");
      }
    },
    getAllFolders: async function() {
      await this.$store.dispatch("projectsModule/getFolders");
    },
    removeOpenedMenuItem: function(item) {
      const indexToDel = this.openMenus.indexOf(item);

      if (indexToDel !== -1) {
        this.openMenus.splice(indexToDel, 1);
      }
    },
    changeMenu: function(page) {
      if (page === "brand-kit" || page === "saved") {
        // persists these menus on page change
        if (this.openMenus.indexOf(page) > -1) {
          this.removeOpenedMenuItem(page);
        } else {
          this.openMenus.push(page);
        }

        if (page === "saved") {
          this.$router.push(`/dashboard/${page}/all`).catch(() => {});
          return;
        }
      }
      this.$router.push(`/dashboard/${page}`).catch(() => {});
    },
    menuToggled: function(page) {
      if (page === "saved") {
        this.$router.push(`/dashboard/${page}/all`).catch(() => {});
      }
    },
    openNewProject: function() {
      this.$router.push(`/c/new`);
    },
    redirectToFolder: function(id) {
      this.$router.push(`/dashboard/saved/${id}`).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.sidebar-dashboard.sidebar-fda2773a {
  .el-submenu .el-menu .el-menu-item {
    padding-left: 69px !important;
  }
}
</style>
